import { graphql } from "gatsby";
import React from "react";
import { ArticleListLayout } from "../components/Articles/ArticleListLayout";
import { ArticleTiles } from "../components/Articles/ArticleTiles";
import { IN_APP_ONLY_TAG } from "../constants";
import { PageSectionContainer } from "../components/PageSection";
import toArticle from "../util/toArticle";
import BlogFilter from "../components/BlogList/BlogFilter";
import styled from "styled-components";

const PageSection = styled(PageSectionContainer)`
    background-color: ${(p) => p.theme.color.background.dim};
    padding: 80px 0px;
`;

const ErrorMessage = styled.p`
    margin-top: 40px;
    color: ${(p) => p.theme.color.error.main};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    text-align: center;
`;
interface Props {
    pageContext: {
        id: string;
        title: string;
    };
    data: GatsbyTypes.TagListQuery;
}

const TagRoute = ({ pageContext, data }: Props) => {
    const { nodes: posts, totalCount } = data.allSanityBlogPost;
    const tag = pageContext.title;

    const articles = posts
        .filter(({ tags }) =>
            tags.every(({ title }) => title !== IN_APP_ONLY_TAG)
        )
        .map(toArticle);

    return (
        <>
            <PageSection>
                <BlogFilter selectedTag={pageContext} />
                <ArticleListLayout>
                    <ArticleTiles articles={articles} />
                </ArticleListLayout>
                {!articles[0] && (
                    <ErrorMessage>
                        No posts tagged with {tag} found
                    </ErrorMessage>
                )}
            </PageSection>
        </>
    );
};

export default TagRoute;

export const tagPageQuery = graphql`
    query TagList($id: String) {
        allSanityBlogPost(
            limit: 1000
            sort: { fields: publishedAt, order: DESC }
            filter: { tags: { elemMatch: { id: { eq: $id } } } }
        ) {
            totalCount
            nodes {
                author {
                    ... on SanityCollaborator {
                        image {
                            _key
                        }
                    }
                    ... on SanityAuthor {
                        image {
                            _key
                        }
                    }
                }
                slug {
                    current
                }
                tags {
                    title
                }
                canonicalUrl
                featuredImage {
                    img {
                        asset {
                            gatsbyImageData(layout: CONSTRAINED, height: 480)
                        }
                    }
                }
                description
                title
                publishedAt(formatString: "MMMM YYYY")
            }
        }
    }
`;
