import { graphql, useStaticQuery } from "gatsby";
import { kebabCase } from "lodash";
import React from "react";
import { gridSquares } from "../../styles/grid";
import styled, { css } from "styled-components";
import { regular12Type } from "../../styles/typography";
import { GRID_MAX_WIDTH } from "../Articles/ArticleTiles";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { BLOG_PATH } from "../../constants/urls";
import Button from "../Button";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { Head } from "../Head";
import { TAGGED_TITLE } from "../../constants/titles";

const Heading = styled.span`
    font-weight: ${(p) => p.theme.fontWeight.bold};
    font-size: 34px;

    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const TagList = styled.ul`
    display: flex;
    justify-content: center;
    max-width: calc(${GRID_MAX_WIDTH} + ${gridSquares(1)});
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: ${(p) => p.theme.grid.gutters.s};
`;

const ClearTag = styled.ul<{
    isVisible: boolean;
}>`
    visibility: hidden;
    display: flex;
    padding-bottom: 20px;

    ${(p) =>
        p.isVisible &&
        css`
            visibility: visible;
        `}
`;

const ClearTagButton = styled(Button)`
    ${regular12Type};
    padding: 6px ${gridSquares(0.5)};
    text-transform: capitalize;
    margin-bottom: 10px;

    background: white;
    color: black;
    border-radius: ${(p) => p.theme.borderRadius.xl};
`;

const BlogFilterWrapper = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const TagButton = styled(Button)<{
    isSelected: boolean;
}>`
    padding: 0px 8px;
    background: white;
    text-transform: capitalize;

    color: grey;
    border: 2px solid grey;

    :hover {
        background: ${(p) => p.theme.color.accentTwo.main};
        border: 2px solid ${(p) => p.theme.color.accentTwo.main};
    }

    ${(p) =>
        p.isSelected &&
        css`
            color: white;
            background: ${p.theme.color.primary.main};
            border: 2px solid ${p.theme.color.primary.main};
        `}
`;

export default function BlogFilter({
    selectedTag,
}: {
    selectedTag?: Pick<GatsbyTypes.SanityTag, "id" | "title">;
}) {
    const data = useStaticQuery<GatsbyTypes.BlogFilterQuery>(graphql`
        query BlogFilter {
            allSanityTag(
                sort: { fields: title, order: ASC }
                filter: { hidden: { ne: true } }
            ) {
                nodes {
                    title
                    id
                }
            }
        }
    `);

    const tags = data.allSanityTag.nodes;

    function isTagSelected() {
        return !!selectedTag;
    }

    // this page is no-indexed because it keeps getting dinged by SEO
    // for low word count
    return (
        <BlogFilterWrapper>
            <Head
                preventIndex={true}
                title={`${TAGGED_TITLE} ${selectedTag?.title}`}
            />
            <ScreenReaderOnly>
                <h2>{selectedTag?.title || "All blog posts"}</h2>
            </ScreenReaderOnly>
            <nav>
                <Heading>Explore articles by topic:</Heading>
                <TagList>
                    {tags.map((tag) => {
                        const isSelected = selectedTag?.id === tag.id;
                        return (
                            <TagButton
                                isSelected={isSelected}
                                key={tag.id}
                                href={
                                    isSelected
                                        ? BLOG_PATH
                                        : `/blog/tagged/${kebabCase(
                                              tag.title
                                          )}/`
                                }
                            >
                                {tag.title}
                            </TagButton>
                        );
                    })}
                </TagList>
                <ClearTag isVisible={isTagSelected()}>
                    <ClearTagButton href={BLOG_PATH} variation="secondaryTwo">
                        <MdClose
                            size={18}
                            style={{
                                position: "relative",
                                top: "3px",
                                right: "7px",
                            }}
                        />
                        Clear tags
                    </ClearTagButton>
                </ClearTag>
            </nav>
        </BlogFilterWrapper>
    );
}
